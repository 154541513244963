import React, {
  ComponentProps,
  ReactNode,
  useRef,
  ReactElement,
  memo,
} from "react";

import { useKeyEnter } from "./use-key-enter";
import { useHighlight } from "./use-highlight";
import "./autocomplete.css";
import { Suggestion } from "../../../api/models";

type ChildProps<S> = {
  suggestion: S;
  index: number;
  highlightedIndex: number;
  highlighted: boolean;
};

type Props<S> = Omit<ComponentProps<"div">, "children" | "onSelect"> & {
  suggestions: S[];
  onSelect: (suggestion: S, index: number) => void;
  onSelectSuggetion: (q: S) => void;
  onSendUniqueQuestion: () => void;
  children: (props: ChildProps<S>) => ReactNode;
  isSuggets: boolean;
};

const preventEvent = (e: React.UIEvent<HTMLElement, UIEvent>) => {
  e.preventDefault();
};

export const Autocomplete = memo(function Autocomplete<S>(props: Props<S>) {
  const { children, suggestions, isSuggets, onSelect, onSelectSuggetion, onSendUniqueQuestion, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { highlightedIndex } = useHighlight(ref);

  useKeyEnter((e) => {
    e.preventDefault();
    if(isSuggets){
      onSelect(suggestions[highlightedIndex - 1], highlightedIndex - 1);
    }else{
      if (highlightedIndex < 1) {
        if(onSendUniqueQuestion) {
          onSendUniqueQuestion()
        }
      } else {
        onSelectSuggetion(suggestions[highlightedIndex - 1])
      }
    }
    // onSelect(suggestions[highlightedIndex], highlightedIndex);
  });
  return (
    <div
      ref={ref}
      className="autocomplete"
      onScroll={preventEvent}
      onPointerDown={preventEvent}
      {...rest}
    >
      {suggestions.map((suggestion, index) => (
        <button
          key={index}
          type="button"
          className="autocomplete-suggestion"
          // data-highlighted={highlightedIndex === index}
          onClick={() => {
            if(isSuggets){
              onSelect(suggestion, index)
            }else{
              onSelectSuggetion(suggestion)
            }
          }}
          data-highlighted={highlightedIndex - 1 === index}
          //onClick={() => onSelectSuggetion(suggestion)}
        >
          {children({
            suggestion,
            index,
            highlightedIndex,
            highlighted: highlightedIndex === index,
          })}
        </button>
      ))}
    </div>
  );
}) as <S>(props: Props<S>) => ReactElement;
