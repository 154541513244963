import { useEffect, useMemo, useRef, useState } from "react";
import {  getSuggestionsV2, RuleSearch, Suggestion } from "../../api";
import { useStore } from "../../hooks/useStore";


export const useSuggestions = (value: string): Suggestion[] => {
  const valueLC = value.toLocaleLowerCase();
  const {
    rootStore: { sessionChatsStore },
  } = useStore();

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fetchDataSuggestionsV2 = async (value: any, url: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    const userString = localStorage.getItem("_user") || "{}";
    const user = JSON.parse(userString);

    timeoutRef.current = setTimeout(async () => {
      try {
        const request = {
          message: value,
          enterprise_id: user?.enterpriseId
        }

        const { data } = await getSuggestionsV2(request, url);
          const mappedSuggestions: Suggestion[] = data.map((e:{
            name: string,
            nameDisplay: string,
            content: string,
            score: number
        })=>{
          return  {
            id: e.name,
            // isSemantic: e.nameDisplay,
            word: e.nameDisplay,
            wordNormalize: e.nameDisplay,
          }
        })
        
        setSuggestions(mappedSuggestions.slice(0,5).reverse());
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([])
      } finally {
      }
    }, 500);
  };

  useEffect(() => {
    let index = (value.indexOf('@') === 0) ? value.indexOf('@') : value.indexOf(' @')
    if(index > -1){
      // fetchDataSuggestionsV2(index == 0 ? value.slice(index + 1).trim() : value.slice(index + 2).trim(), 'https://kiennghi.trolyao.org/api/chatbot/target/search')
      const valueSearch = (index == 0 ? value.slice(index + 1).trim() : value.slice(index + 2).trim())
      fetchDataSuggestionsV2(valueSearch, 'https://kiennghi.trolyao.org/api/chatbot/target/recommend')
    }else{
      setSuggestions([])
    }
  }, [value]);


  useEffect(() => {
    setSuggestions([])
  }, [sessionChatsStore.ruleSearch]);

  return useMemo(() => {
    return valueLC
      ? suggestions
      : [];
  }, [suggestions]);
};
