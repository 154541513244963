import { TLabel } from "@api/models";
import './index.css'
import { FC, HTMLAttributes, ReactElement, ReactNode } from "react";

export interface TagsProps extends HTMLAttributes<HTMLDivElement>{
    type: TLabel;
    children: ReactElement|ReactNode|string;
}

const Tags:FC<TagsProps> = ({type, children,...rest})=>{
    const tags = {
        "success": <div className="tags-item success" {...rest}>{children}</div> , 
        "error": <div className="tags-item error" {...rest}>{children}</div> ,
        "warning":<div className="tags-item warning" {...rest}>{children}</div>
    }
    return tags[type]
}

export default Tags;
