import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import sendRed from "../../assets/send_red.svg"
import ic_speak from "../../assets/speak.gif"
import styles from "./QuestionInput.module.css";
import { useViewport } from "../../hooks/useViewport";
import eventBus from "../../plugins/EventBus";
import ChatVoice from "../MicrophoneRecorder/Voice";
import { Dismiss20Regular, Search20Regular } from "@fluentui/react-icons";
import { getSuggestions, RuleSearch } from "../../api";
import sematic_icon from "../../assets/uiv2/airecomment.svg"
import { useStore } from "../../hooks/useStore";
import { TextareaPage } from "./textarea-page";
import { isEmpty } from "lodash";
import showToasty from "../../hooks/useNotify";


interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
}

interface Suggestion {
    id: string
    isSemantic: number;
    word: string;
    wordNormalize: string
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {
    const {
        rootStore: { sessionChatsStore },
    } = useStore();
    const viewPort = useViewport();
    const isMobile = viewPort.width <= 768;
    const [question, setQuestion] = useState<string>("");
    const [disableInput, setDisableInput] = useState<boolean>(false);
    const [textListening, setTextListening] = useState<string>("")
    const [hideListening, setHideListening] = useState<boolean>(true)
    const [isCssInputchange, setIsCssInputchange] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

    const handleUserKeyPress = (event: any) => {
        if (disabled) {
            return;
        }
        const { key, keyCode } = event;
        if (keyCode === 32) {
            if (event.target && event.target.localName !== 'input' && event.target.localName !== 'textarea') {
                hideListening ? eventBus.dispatch('startRecordingParent', {}) : stopRecording()
            }

        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const sendQuestion = () => {
        if(!isEmpty(sessionChatsStore.getMessageRating)){
            showToasty("Vui lòng hoàn thành đánh giá!", "error");
            return;
        }
        if (disabled || !question.trim()) {
            return;
        }
        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onClickSendQuestion = (q: Suggestion) => {
        if (!q.wordNormalize?.trim() && !q.word?.trim()) {
            return;
        }
        sessionChatsStore.SET_ID_HOT_ISSUE(q.id)
        sessionChatsStore.SET_ISSUE_BUTTON({
            display_name: '',
            normalized_names: [`${q.wordNormalize ? q.wordNormalize : q.word}`],
            type: 'single',
        })
        onSend(q.wordNormalize ? q.wordNormalize : q.word);
        if (clearOnSend) {
            setQuestion("");
            setSuggestions([])
        }
    };


    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (event: any) => {
        const newValue = event
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 10000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const clearQuestion = () => {
        setQuestion("");
        setSuggestions([])
    }

    const makeChatVoice = (textVoice: string) => {
        setQuestion("");
        setTextListening(textVoice)
    }

    const changeRecord = (val: boolean) => {
        setHideListening(val)
    }

    const sendTextMess = (t: string) => {
        onSend(t)
        setTextListening("")
    }

    const stopRecording = () => {
        eventBus.dispatch('stopRecordingParent', {})
        setHideListening(true)
        setTextListening("")
    }
    const startVoice = () => {
        setTextListening("")
    }

    const isUserPermission = () => {
        const userLocal = JSON.parse(localStorage.getItem('_user') || '{}')
        const isHavePermission = userLocal?.username === "0345923535" || userLocal?.username === "0362752732"
        return isHavePermission
    }
    const firstUpdate = useRef(false);
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        isUserPermission()
        eventBus.on('empty-conversation', (data: any) => {
            if (data) {
                setQuestion('');
            }
            setDisableInput(data)
        });

        firstUpdate.current = true;
        return () => { };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                event.target?.id !== 'box_auto_suggession'
            ) {
                setSuggestions([])
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [])

    useEffect(() => {
        if (!question && suggestions.length > 0) {
            setSuggestions([])
        }
    }, [suggestions])

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {!hideListening &&
                    <div style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "start" }}>
                        <span>{textListening}</span>
                    </div>
                }
                <Stack horizontal id="questionInputContainerId" className={styles.questionInputContainer} style={{ display: !hideListening ? 'none' : 'flex' }}>
                    <TextareaPage
                        question={question}
                        setQuestion={setQuestion}
                        setIsCssInputchange={setIsCssInputchange}
                        onClickSendQuestion={(q) => onClickSendQuestion(q)}
                        onSendUniqueQuestion={sendQuestion}
                    />

                    {
                        question ?
                            <button className={styles.clearButton} onClick={clearQuestion}>
                                {/* <AiOutlineClose></AiOutlineClose> */}
                                <Dismiss20Regular style={{ color: "var(--text-primary-color)" }} />
                            </button> :
                            <>
                                {
                                    isUserPermission() ?
                                        <ChatVoice
                                            makeChatVoice={(q: string) => makeChatVoice(q)}
                                            changeRecord={(v: boolean) => changeRecord(v)}
                                            onSendText={(t: string) => sendTextMess(t)}
                                            stopRecording={stopRecording}
                                            startVoice={startVoice}
                                            disabled={disabled}
                                        /> : null
                                }
                            </>
                    }
                    {!isCssInputchange ?
                        <div className={styles.questionInputCircle}></div>
                        : null
                    }

                    <div className={`${styles.questionInputDot} ${styles.questionInputDotTop}`}></div>
                    <div className={`${styles.questionInputDot} ${styles.questionInputDotBottom}`}></div>
                </Stack>

                {!hideListening &&
                    <div className={`${styles.questionInputSendButton} ${isMobile ? styles.questionInputSendButtonMobile : ''}`} onClick={stopRecording}>
                        <img src={ic_speak} alt="" style={{ maxHeight: "70px", height: isMobile ? '60px' : '70px' }} />
                    </div>
                }
                {hideListening &&
                    <div className={`${styles.questionInputSendButton} ${isMobile ? styles.questionInputSendButtonMobile : ''}`} onClick={sendQuestion}>
                        <img src={sendRed} alt="" style={{ maxHeight: "24px", height: isMobile ? '20px' : '22px' }} />
                    </div>}
            </div>
        </>
    );
};
