import { action, computed, makeObservable, observable } from "mobx";
import { issue_buttons, ITrackSearch, listAllChatSession, listAllDraft, resExpanded, RuleChat, RuleSearch } from "../api";
import { IRootStore } from "./RootStore";
import _, { filter, map } from 'lodash';
import { DraftStatus, ItemsDraftStatus } from "../api/models";


export interface ISessionChats {
    active: boolean
    createdAt: number
    firstMessage: string
    id: string
    pinned: boolean
    sessionName: string
  }

export class SessionChatsStore {
  issueButton: issue_buttons = {
    display_name: "",
    normalized_names: [],
    type: ""
  }
  sessionchats: ISessionChats[] = [];
  trackSearchs: ITrackSearch[] = [];
  routerId = '';
  _refresh_token = '';
  questionFollowup = '';
  _id_hot_issue= '';
  messageRating='';
  _payload_generic= '';
  _rule_search = RuleSearch.knowledge;
  _unit_name = ''
  _rule_chat = RuleChat.chat
  _drafts: any[] = [];
  needSearch= false;
  isClickSpecific= false;
  isClickFollowupQuestion = false;
  isHoverPopup = false
  isPopupVisible = false
  errorCount: number = 0;
  _continue_step_search: number = 0;
  _expanded_search: boolean = false;
  _show_button_reference: boolean = false;
  _res_click_expanded_faq: resExpanded =  {isExpandedFaq: false, title: '', content: '', isSearchFaq: false}
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      _payload_generic: observable,
      _drafts: observable,
      routerId: observable,
      issueButton: observable,
      _unit_name: observable,
      _refresh_token: observable,
      _rule_search: observable,
      _rule_chat: observable,
      _id_hot_issue: observable,
      sessionchats: observable,
      trackSearchs: observable,
      errorCount: observable,
      questionFollowup: observable,
      needSearch: observable,
      isClickSpecific: observable,
      isClickFollowupQuestion: observable,
      isHoverPopup: observable,
      isPopupVisible: observable,
      _continue_step_search: observable,
      _expanded_search: observable,
      _show_button_reference: observable,
      _res_click_expanded_faq: observable,
      messageRating: observable,
      fetchSessionChat: action,
      updateSessionChat: action,
      removeChatById: action,
      removeAllChat: action,
      addItem: action,
      editItem: action,
      updateMessagesChat: action,
      reloadChatPinUnpin: action,
      getIdParams: action,
      updateErrorCountNetwork: action,
      changeQuestionFollowup: action,
      changeNeedSearchSpecific: action,
      changeIsClickSpecific: action,
      changeIsHoverPopup: action,
      changePopupVisible: action,
      tokenChange: action,
      SET_TRACK_SEARCH: action,
      SET_ID_HOT_ISSUE: action,
      SET_RULE_SEARCH: action,
      SET_RULE_CHAT: action,
      SET_ISSUE_BUTTON: action,
      SET_DRAFT: action,
      ADD_DRAFT: action,
      GET_DRAFT: action,
      SET_STEP_SEARCH: action,
      SET_EXPANDED_SEARCH: action,
      SET_IS_BUTTON_REFERENCE: action,
      SET_CLICK_EXPANDED_FAQ: action,
      SET_UNIT_NAME: action,
      idRouter: computed,
      getSessionChat: computed,
      errorCountNetwork: computed,
      getQuestionFollowup: computed,
      getIsHoverQuestion: computed,
      getIsPopupVisible: computed,
      tokenHind: computed,
      need_search_specific: computed,
      is_click_specific: computed,
      track_searchs: computed,
      idHotIssue: computed,
      ruleSearch: computed,
      ruleChat: computed,
      issue_button: computed,
      drafts: computed,
      pendingDrafts: computed,
      approvedDrafts: computed,
      cancelledDrafts: computed,
      continue_step_search: computed,
      expanded_search: computed,
      show_button_reference: computed,
      isClickExpandedFaq: computed,
      SET_GENERIC_BUTTON: action,
      payloadGeneric: computed,
      unitName: computed
    });
    this.rootStore = rootStore;
  }

  async apiGetChatSession() {
    const userIdLocal = JSON.parse(localStorage.getItem('_user') || '{}')

    const requestGet: any = {
        userId: userIdLocal.userId,
    }
    const result = await listAllChatSession(requestGet);
    return result
  }

  async GET_DRAFT(request: any) {
    this._drafts = []
    const { data } = await listAllDraft(request)
        if(data && data?.length) {
            const result = map(data, (v: any) => {
                return {
                    createdAt: v?.createdAt, 
                    firstMessage: v?.question,
                    id: v?.id,
                    pinned: false,
                    sessionName: v?.name,
                    approveStatus: v?.approveStatus,
                    status: v?.status,
                    isDraft: v?.isDraft,
                    messageId: v?.messageId
                }
            })
            this._drafts = result
        }else {
          this._drafts = []
        }
  }

  async SET_DRAFT(id: string) {
    let newState = filter(this._drafts, (item: any) => item?.id !== id);
    this._drafts = newState;
  }

  async ADD_DRAFT(draft: any) {
    let newState = {
      createdAt: draft?.createdAt, 
      firstMessage: draft?.question,
      id: draft?.id,
      pinned: false,
      sessionName: draft?.name,
      isDraft: draft?.isDraft,
      approveStatus: draft?.approveStatus
    }
    this._drafts.unshift(newState);
  }

  async fetchSessionChat() {
    const result = await this.apiGetChatSession();
    this.sessionchats = result?.data ?? [];    
  }

  async updateSessionChat(chats: ISessionChats[]) {
    this.sessionchats = chats ?? [];    
  }

  addItem = (newItem: ISessionChats) => {
    this.sessionchats.unshift(newItem);
  }

  editItem = (sessionId: string, sessionName: string) => {
    const existingItem: any = this.sessionchats.map((item: any, index: any) => {
        if(item?.id === sessionId) {
            this.sessionchats[index].sessionName = sessionName
        }
        return item
    });
    this.sessionchats = existingItem;
  }

  reloadChatPinUnpin = (chats: any) => {
    this.sessionchats = chats
  }

  removeChatById(sessionId: string) {
    let newState = this.sessionchats.filter((item: any) => item?.id !== sessionId);
    this.sessionchats = newState;
  }
  removeAllChat() {
    this.sessionchats = [];
  }

  updateMessagesChat(sessionId: string, firstMessage: string = '') {
    const existingItem: any = this.sessionchats.map((item: any, index: any) => {
        if(item?.id === sessionId) {
            this.sessionchats[index].firstMessage = firstMessage
        }
        return item
    });
    this.sessionchats = existingItem;
  }

  updateErrorCountNetwork(num: number) {
    this.errorCount = num
  }

  SET_ISSUE_BUTTON(issue: issue_buttons) {
    this.issueButton = {
      display_name: issue.display_name,
      normalized_names: issue.normalized_names,
      type: issue.type,
    };
  }


  changeQuestionFollowup(text: string) {
    this.questionFollowup = text
  }

  changeNeedSearchSpecific(bol: boolean) {
    this.needSearch = bol
  }

  changeIsClickSpecific(bol: boolean){
    this.isClickSpecific = bol
  }

  changeIsClickFollowupQuestion(update: boolean) {
    this.isClickFollowupQuestion = update
  }

  changeIsHoverPopup(update: boolean) {
    this.isHoverPopup = update
  }

  changePopupVisible(update: boolean) {
    this.isPopupVisible = update
  }

  async getIdParams(id: string) {
    this.routerId = id ?? ''
  }

  tokenChange(token: string) {
    this._refresh_token = token ?? ''
  }

  SET_ID_HOT_ISSUE(id: string) {
    this._id_hot_issue = id ?? ''
  }

  SET_UNIT_NAME(name: string) {
    this._unit_name = name ?? ''
  }

  SET_RULE_SEARCH(rule: string) {
    if (rule === RuleSearch.knowledge) {
      this._rule_search = RuleSearch.knowledge;
    } else if (rule === RuleSearch.reference) {
      this._rule_search = RuleSearch.reference;
    }
  }

  SET_RULE_CHAT(rule: string) {
    if (rule === RuleChat.chat) {
      this._rule_chat = RuleChat.chat;
    } else if (rule === RuleChat.draft) {
      this._rule_chat = RuleChat.draft;
    }
  }
  SET_TRACK_SEARCH = (newItem: ITrackSearch) => {
    this.trackSearchs.push(newItem);
  }
  CLEAR_TRACK_SEARCH = () => {
    this.trackSearchs = []
  }
  SET_STEP_SEARCH = (step: number) => {
    this._continue_step_search = step
  }
  SET_EXPANDED_SEARCH = (expanded: boolean) => {
    this._expanded_search = expanded
  }
  SET_IS_BUTTON_REFERENCE = (bol: boolean) => {
    this._show_button_reference = bol
  }
  SET_CLICK_EXPANDED_FAQ = (resFaq: resExpanded) => {
    this._res_click_expanded_faq = {...resFaq}
  }

  SET_MESSAGE_RATING = (messageId: string) => {
    this.messageRating = messageId
  }

  SET_GENERIC_BUTTON = (payload: string) => {
    this._payload_generic = payload
  }

  get getSessionChat() {
    return this.sessionchats;
  }

  get need_search_specific() {
    return this.needSearch
  }

  get is_click_specific() {
    return this.isClickSpecific
  }

  get tokenHind() {
    return this._refresh_token;
  }
  get idHotIssue() {
    return this._id_hot_issue;
  }

  get idRouter() {
    return this.routerId
  }
  get errorCountNetwork() {
    return this.errorCount
  }

  get getQuestionFollowup() {
    return this.questionFollowup
  }

  get getIsClickFollowupQuestion() {
    return this.isClickFollowupQuestion
  }

  get getIsHoverQuestion() {
    return this.isHoverPopup
  }

  get getIsPopupVisible() {
    return this.isPopupVisible
  }
  get ruleSearch() {
    return this._rule_search
  }
  get ruleChat() {
    return this._rule_chat
  }
  get issue_button() {
    return this.issueButton
  }
  get track_searchs() {
    const uniqueSearchResults: ITrackSearch[] = _.uniqBy(this.trackSearchs, JSON.stringify);
    return uniqueSearchResults
  }

  get drafts() {
    const items = map(this._drafts, (item: any) => {
        let newItem = { ...item };
        if (item?.status === ItemsDraftStatus.noprocess) {
            newItem.status = ItemsDraftStatus.processing;
        }
        return newItem
    });
    return items;
  }

  get pendingDrafts() {
    const items = filter(this.drafts, ((v) => v?.status === ItemsDraftStatus.pending))
    return items 
  }
  get approvedDrafts() {
    const items = filter(this.drafts, ((v) => v?.status === ItemsDraftStatus.processed))
    return items 
  }
  get processingDrafts() {
    const items = filter(this.drafts, ((v) => v?.status === ItemsDraftStatus.processing || v?.status === ItemsDraftStatus.noprocess))
    return items 
  }
  get cancelledDrafts() {
    const items = filter(this.drafts, ((v) => v?.status === 'TU_CHOI'))
    return items 
  }

  get continue_step_search() {
    return this._continue_step_search
  }
  get expanded_search() {
    return this._expanded_search
  }
  get show_button_reference() {
    return this._show_button_reference
  }
  get isClickExpandedFaq() {
    return this._res_click_expanded_faq
  }
  get getMessageRating() {
    return this.messageRating
  }
  get payloadGeneric() {
    return this._payload_generic
  }
  get unitName() {
    return this._unit_name
  }
}