import { useRef, useState, ChangeEvent, useMemo, useLayoutEffect, useEffect, SetStateAction, Dispatch } from "react";
import useEvent from "react-use-event-hook";

import { Textarea } from "../../shared/ui/textarea";
import { Autocomplete } from "../../shared/ui/autocomplete";
import { Highlight } from "../../shared/ui/highlight";
import { insertString } from "../../shared/common/insert-string";
import { selectWord } from "../../shared/common/select-word";
import { useKeyEsc } from "../../shared/hooks/use-key-esc";
import { useSuggestions } from "../../shared/hooks/use-suggestions";
import { Suggestion } from "../../api";
import { type } from "os";
import sematic_icon from "../../assets/uiv2/airecomment.svg"
import { Search20Regular } from "@fluentui/react-icons";
import styles from "./QuestionInput.module.css";
import { useKeyEnter } from "../../shared/ui/autocomplete/use-key-enter";

type Props = {
  question: string
  setIsCssInputchange: Dispatch<SetStateAction<boolean>>
  setQuestion: Dispatch<SetStateAction<string>>
  onClickSendQuestion: (q: Suggestion) => void
  onSendUniqueQuestion: () => void
}

export const TextareaPage = ({ question, setQuestion, setIsCssInputchange, onClickSendQuestion, onSendUniqueQuestion }: Props) => {
  const lsPlaceHolder = ['Đặt câu hỏi tại đây...', 'Lưu ý: Gõ @ nếu muốn hệ thống gợi ý tên chỉ tiêu']
  const refInterval:any = useRef(null)
  const [suggested, suggest] = useState(false);
  const [indexPlaceHolder, setIndexPlaceHolder] = useState(0);

  const ref = useRef<any>(null);
  const selectionEndRef = useRef(0);
  const indexSuggets = (question.indexOf(' @') > 0) ? question.indexOf(' @') : question.indexOf('@')
  const isSuggets = indexSuggets > -1

  const { startIndex, endIndex, word } = useMemo(
    () => selectWord(question, selectionEndRef.current),
    [question]
  );

  const handleChange = useEvent(
    (nextValue: string, e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!nextValue) {
        setQuestion("");
      } else if (nextValue.length <= 10000) {
        setQuestion(nextValue);
      }
      suggest(true);
      selectionEndRef.current = e.target.selectionEnd;
    }
  );

  const handleSelect = useEvent((suggestion: Suggestion, e:any,...rest) => {
    if(!suggestion) return
    const nextValue = suggestion.wordNormalize;
    suggest(false);

    if(isSuggets){
      const suggests = question.slice(0 , indexSuggets) + ' ' + nextValue
      setQuestion(suggests);
      setTimeout(()=>{
        ref.current.selectionStart = suggests.length;
      })
    }
    else{
      setQuestion(nextValue)
    }
    
  });

  const handleBlur = useEvent(() => suggest(false));
  
  useKeyEsc(() => suggest(false));

  useKeyEnter((e) => {
    e.preventDefault();
    // setQuestion()
    if(!suggestRenderCause) {
      onSendUniqueQuestion();
    }
  });

  useLayoutEffect(() => {
    const textareaEl = ref.current;
    if (!textareaEl) {
      return;
    }
    if (!suggested) {
      textareaEl.setSelectionRange(endIndex, endIndex);
    }
  }, [endIndex, suggested]);

  useEffect(() => {
    if (!question) {
      suggest(false)
    }

    //custom textarea auto resize height
    const textareaEl = ref.current;
    const getTextArea = document.getElementById('textarea-custom')
    const getQuestionInput = document.getElementById('questionInputContainerId')
    if (textareaEl) {
      ref.current.style.height = "0px";
      const scrollHeight = ref.current.scrollHeight;
      ref.current.style.height = scrollHeight + "px";
    }
    if (getTextArea && textareaEl) {
      getTextArea.style.height = "28px";
      const scrollHeight = ref.current.scrollHeight;
      if (setIsCssInputchange) {
        setIsCssInputchange(scrollHeight > 28 ? true : false)
      }
      getTextArea.style.height = scrollHeight + "px";
    }
    if (getQuestionInput && textareaEl) {
      const scrollHeight = ref.current.scrollHeight;
      if (scrollHeight > 28) {
        getQuestionInput.style.borderTopLeftRadius = '16px'
        getQuestionInput.style.borderBottomLeftRadius = '16px'
      } else {
        getQuestionInput.style.borderTopLeftRadius = '50px'
        getQuestionInput.style.borderBottomLeftRadius = '50px'
      }
    }
  }, [question])

  useEffect(()=>{
    refInterval.current = setInterval(()=>{
      setIndexPlaceHolder((index)=> index ? 0 : 1)
    }, 10000)

    return (()=>{
      clearInterval(refInterval.current)
    })
  },[])

  const filtredSuggestions = useSuggestions(word);
  const suggestRenderCause = suggested && filtredSuggestions.length > 0;

  function renderIcons(suggestion:any){
    if(isSuggets) return 

    return suggestion.isSemantic === 1 ?
      (<img src={sematic_icon} className={styles.sematicAi} />) :
      (<div style={{ marginRight: '8px' }}>
        <Search20Regular style={{ marginLeft: '-5px', color: 'var(--commands-layout-color)' }} />
      </div>)
  }

  return (
    <Textarea
      style={{ color: 'var(--text-primary-color)', width: "100%", marginLeft: '10px', border: "none", outline: "none", background: "var(--bg-input-chat)" }}
      ref={ref}
      value={question}
      placeholder={lsPlaceHolder[indexPlaceHolder]}
      onBlur={handleBlur}
      onChange={handleChange}
      suggest={
        suggestRenderCause && (
          <Autocomplete
            isSuggets={isSuggets}
            suggestions={filtredSuggestions}
            onSelect={handleSelect}
            onSelectSuggetion={(q: Suggestion) => onClickSendQuestion(q)}
            onSendUniqueQuestion={() => {
              onSendUniqueQuestion()
            }}
          >
            {({ suggestion }: any) => (
              <div className={styles.suggestion}>
                {
                  renderIcons(suggestion)
                }
                <div className="">
                  <Highlight match={word}>{suggestion?.wordNormalize ? suggestion?.wordNormalize : suggestion?.word}</Highlight>
                </div>
              </div>
            )}
          </Autocomplete>
        )
      }
    />
  );
};
