import { TStatus } from "@api/models";
import Tags from "@components/Ui/Tags";
import { ReactElement, FC } from "react";

export interface IPropsStatusTags{
    type: TStatus
}

const StatusTags:FC<IPropsStatusTags> =({type})=> {
    const STATUS: { [e: string]: ReactElement } = {
        EXPIRED: <Tags style={{marginLeft: 10}} type="error">Đã hết hiệu lực</Tags>,
        NEAR_EXPIRATION: <Tags style={{marginLeft: 10}} type="warning">Sắp hết hiệu lực</Tags>
    };
    return STATUS[type];
}

export default StatusTags